import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './components/Navigation';
import Header from './components/Header';
import About from './components/About';
import Timeline from './components/Timeline';
import Skills from './components/Skills';
import Projects from './components/Projects';
import Contact from './components/Contact';
import Footer from './components/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Importiere das AOS CSS
import './App.css';
import './styles/skills.css';
import 'typeface-teko';

function App() {


    return (
        <>


            <Navbar/>
            <Header/>
            <About/>

            <Timeline></Timeline>
            <Skills/>
            <Projects/>
            <Contact/>
            <Footer/>
        </>
    );
}

export default App;
