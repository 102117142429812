import React, { useEffect } from "react";
import '../styles/about.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { MdEmail } from "react-icons/md";
import { IoLogoLinkedin } from "react-icons/io5";
import { FaGithub } from "react-icons/fa";

function Contact() {
    useEffect(() => {
        AOS.init({
            duration: 1000, // Dauer der Animation in ms
        });
    }, []);

    return (
        <section id="contact" className="text-warning p-5 custom-border">
            <div className="container mt-5 box">
                <div className="d-flex justify-content-center align-items-center">
                    <h1 className="display-5 text-dark mb-5 text-center custom-border-button fw-bold">
                        <span className="text-success">Contact</span> Me
                    </h1>
                </div>
                <div className="d-flex justify-content-center">
                    <div className="d-flex flex-column flex-md-row align-items-center">
                        <div className="text-center mb-4 mx-3" data-aos="fade-up">
                            <MdEmail className="text-success" size={40} />
                            <h3 className="text-success">Email</h3>
                            <p>
                                <a href="mailto:timfrisch530@gmail.com" className="text-dark">
                                    Email Me
                                </a>
                            </p>
                        </div>
                        <div className="text-center mb-4 mx-3" data-aos="fade-up">
                            <IoLogoLinkedin className="text-success" size={40} />
                            <h3 className="text-success">LinkedIn</h3>
                            <p>
                                <a href="https://www.linkedin.com/in/frisch-tim-47a34131b/" className="text-dark" target="_blank" rel="noopener noreferrer">
                                    LinkedIn Profile
                                </a>
                            </p>
                        </div>
                        <div className="text-center mb-4 mx-3" data-aos="fade-up">
                            <FaGithub className="text-success" size={40} />
                            <h3 className="text-success">GitHub</h3>
                            <p>
                                <a href="https://github.com/timfrisch" className="text-dark" target="_blank" rel="noopener noreferrer">
                                    GitHub Profile
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Contact;
