import React, {useEffect} from 'react';
import { Container, Button } from 'react-bootstrap';
import { FaLinkedin, FaGithub } from "react-icons/fa";
import AOS from 'aos';
import 'aos/dist/aos.css';
const Header = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000, // Dauer der Animation in ms
        });
    }, []);
    return (
        <header className="vh-75 d-flex align-items-center justify-content-center text-center " id="home">
            <div>
                <h1 className="text-dark mt-5 mb-3 " data-aos={"fade-up"}>Hi, I'm </h1>
                <div className="position-relative">
                    <svg id="nameHome" width="678" height="102" viewBox="0 0 678 102" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M674.779 6.784V4.284H672.279H660.631H658.131V6.784V43.452H617.692V6.784V4.284H615.192H603.544H601.044V6.784V96V98.5H603.544H615.192H617.692V96V58.052H658.131V96V98.5H660.631H672.279H674.779V96V6.784Z"
                            stroke="black" stroke-width="5"/>
                        <path
                            d="M520.958 9.35272L520.958 9.35269L520.947 9.35955C513.81 13.515 508.164 19.303 504.027 26.6752C499.871 33.9972 497.817 42.2358 497.817 51.328C497.817 60.4226 499.872 68.6631 504.031 75.9866C508.17 83.275 513.816 89.0164 520.947 93.1684L520.963 93.1778L520.979 93.1869C528.201 97.2495 536.179 99.268 544.86 99.268C554.999 99.268 563.968 96.8613 571.673 91.9662L571.682 91.9606L571.691 91.9549C579.412 86.9588 585.027 79.9154 588.534 70.9244L589.863 67.516H586.205H572.253H570.651L569.982 68.9706C567.698 73.9322 564.433 77.6789 560.191 80.3026C556.049 82.832 550.976 84.156 544.86 84.156C539.036 84.156 533.89 82.8047 529.357 80.1473C524.827 77.4919 521.264 73.7467 518.655 68.8488C516.058 63.8877 514.721 58.0709 514.721 51.328C514.721 44.4897 516.062 38.6792 518.651 33.8146L518.655 33.8073L518.659 33.8C521.271 28.8136 524.835 25.0314 529.357 22.3807C533.89 19.7233 539.036 18.372 544.86 18.372C550.965 18.372 556.03 19.7339 560.168 22.3396L560.177 22.3451L560.186 22.3505C564.43 24.9743 567.697 28.722 569.982 33.6854L570.651 35.14H572.253H586.205H589.85L588.537 31.7395C585.033 22.6647 579.418 15.5734 571.691 10.5731C563.983 5.58588 555.008 3.13199 544.86 3.13199C536.169 3.13199 528.184 5.19808 520.958 9.35272Z"
                            stroke="black" stroke-width="5"/>
                        <path
                            d="M440.909 96.0927L440.934 96.1043L440.96 96.1154C446.012 98.3201 451.651 99.396 457.831 99.396C464.301 99.396 470.051 98.1366 474.999 95.5225C479.867 92.9509 483.645 89.5388 486.215 85.2542C488.745 81.0375 490.028 76.4987 490.028 71.68C490.028 65.6948 488.669 60.65 485.66 56.8314C482.867 53.2046 479.472 50.4999 475.48 48.7841C471.735 47.0924 466.872 45.4348 460.933 43.7994C456.127 42.4501 452.425 41.2838 449.795 40.3003C447.5 39.3312 445.562 37.972 443.951 36.2207C442.642 34.7148 441.899 32.6983 441.899 29.952C441.899 26.0611 443.184 23.3299 445.576 21.4029L445.592 21.3898L445.608 21.3765C448.139 19.2672 451.501 18.116 455.911 18.116C460.689 18.116 464.251 19.3228 466.85 21.5003L466.865 21.5132L466.881 21.5259C469.634 23.7583 471.018 26.1957 471.351 28.8541L471.625 31.044H473.831H486.631H489.391L489.119 28.2983C488.371 20.7226 485.01 14.5474 479.086 9.93653C473.258 5.33342 465.847 3.13199 457.063 3.13199C450.965 3.13199 445.441 4.20918 440.544 6.42661L440.536 6.43032L440.528 6.4341C435.705 8.65981 431.873 11.8548 429.12 16.0322C426.354 20.2287 424.995 25.0246 424.995 30.336C424.995 36.2404 426.313 41.2557 429.201 45.1398L429.223 45.1687L429.245 45.1971C432.038 48.7343 435.387 51.3914 439.291 53.1051L439.301 53.1095L439.311 53.1139C443.053 54.7176 447.918 56.2895 453.865 57.841C458.597 59.1089 462.29 60.2747 464.979 61.334C467.451 62.3076 469.46 63.7073 471.059 65.5236C472.457 67.1125 473.252 69.2583 473.252 72.192C473.252 75.83 471.937 78.721 469.25 81.0546C466.679 83.2779 462.976 84.54 457.831 84.54C452.427 84.54 448.692 83.2345 446.235 80.997C443.691 78.5146 442.253 75.5283 441.887 71.9382L441.657 69.692H439.399H426.984H424.439L424.484 72.2359C424.577 77.5691 426.085 82.3712 429.036 86.5599L429.044 86.571L429.052 86.582C431.978 90.6408 435.96 93.8014 440.909 96.0927Z"
                            stroke="black" stroke-width="5"/>
                        <path
                            d="M412.192 6.784V4.284H409.692H398.044H395.544V6.784V96V98.5H398.044H409.692H412.192V96V6.784Z"
                            stroke="black" stroke-width="5"/>
                        <path
                            d="M364.984 97.2583L365.707 98.5H367.145H381.096H385.509L383.241 94.7148L362.554 60.198C368.497 58.3623 373.226 55.2584 376.564 50.7859C380.489 45.6408 382.444 39.6876 382.444 33.024C382.444 27.5929 381.197 22.6673 378.645 18.3185C376.059 13.9128 372.164 10.4864 367.102 7.99982C362.059 5.47998 355.979 4.284 348.968 4.284H320.168H317.668V6.784V96V98.5H320.168H331.816H334.316V96V62.02H344.459L364.984 97.2583ZM361.46 22.5535L361.472 22.5653L361.485 22.5768C364.01 24.8975 365.412 28.2571 365.412 33.024C365.412 37.6778 364.024 41.1786 361.421 43.7881C358.899 46.2338 354.905 47.676 348.968 47.676H334.316V18.884H348.968C355.051 18.884 359.042 20.2698 361.46 22.5535Z"
                            stroke="black" stroke-width="5"/>
                        <path
                            d="M308.6 6.784V4.284H306.1H255.668H253.168V6.784V96V98.5H255.668H267.316H269.816V96V58.308H298.804H301.304V55.808V46.336V43.836H298.804H269.816V18.756H306.1H308.6V16.256V6.784Z"
                            stroke="black" stroke-width="5"/>
                        <path
                            d="M204.289 7.42401V4.92401H201.789H189.374H187.753L187.092 6.40275L156.605 74.5201L126.119 6.40275L125.458 4.92401H123.837H111.293H108.793V7.42401V96V98.5H111.293H122.941H125.441V96V41.5476L150.227 97.0199L150.888 98.5H152.509H160.702H162.324L162.985 97.0178L187.642 41.7013V96V98.5H190.142H201.789H204.289V96V7.42401Z"
                            stroke="black" stroke-width="5"/>
                        <path
                            d="M93.9415 6.784V4.284H91.4415H79.7935H77.2935V6.784V96V98.5H79.7935H91.4415H93.9415V96V6.784Z"
                            stroke="black" stroke-width="5"/>
                        <path
                            d="M67.9555 6.784V4.284H65.4555H5.03949H2.53949V6.784V16.256V18.756H5.03949H26.9875V96V98.5H29.4875H41.1355H43.6355V96V18.756H65.4555H67.9555V16.256V6.784Z"
                            stroke="black" stroke-width="5"/>
                    </svg>

                </div>

                <p className="text-dark mt-5 mb-5 w-100 text-center fs-3" data-aos={"fade-up"}>A creative problem-solver in the realm of Full-Stack Development, dedicated to designing and building innovative, applications</p>
                <div className="social-icons" data-aos={"fade-up"}>
                    <a href="https://github.com/Timfrisch" target="_blank" rel="noopener noreferrer"
                       className="social-icon text-dark">
                        <FaGithub/>
                    </a>
                    <a href="https://linkedin.com/in/IhrLinkedinProfil" target="_blank" rel="noopener noreferrer"
                       className="social-icon text-dark ms-5">
                        <FaLinkedin/>
                    </a>
                </div>
                <a href="#contact" className="btn btn-success btn-lg rounded-5 mt-4 " data-aos={"fade-up"}>Contact Me</a>
            </div>
        </header>
    );
};

export default Header;
