import React from 'react';

function Footer() {
    return (
        <footer className="p-5" id="footer">
            <div className="container text-dark">
                <div className="row">
                    <div className="col-md-4 mb-3 mb-md-0">
                        <h5>About Me</h5>
                        <p>I am Tim Frisch, a 21-year-old IT professional from Junglinster, Luxembourg. I hold a Diploma
                            of Technician in Computer Science (DT) and have a passion for technology and
                            problem-solving.</p>
                    </div>
                    <div className="col-md-4 mb-3 mb-md-0">
                        <h5>Quick Links</h5>
                        <ul className="list-unstyled">
                            <li><a href="#home" className="text-dark">Home</a></li>
                            <li><a href="#skills" className="text-dark">Skills</a></li>
                            <li><a href="#projects" className="text-dark">Projects</a></li>
                            <li><a href="#contact" className="text-dark">Contact</a></li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <h5>Contact Info</h5>
                        <ul className="list-unstyled">
                            <li className="text-success">Email: <a href="mailto:timfrisch530@gmail.com"
                                                                   className="text-dark">timfrisch530@gmail.com</a></li>
                            <li className="text-success">Phone: <a href="tel:+621816424"
                                                                   className="text-dark">+621816424</a></li>
                            <li className="text-success">Location:<span
                                className="text-dark"> Junglinster, Luxembourg</span></li>
                        </ul>
                    </div>
                </div>
                <div className="text-center mt-4">
                    <p className="mb-0">&copy; 2024 Frisch Tim. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;