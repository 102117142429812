import React, {useEffect} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';
// Definiere die Timeline-Daten korrekt
const timelineData = [
    {
        date: "2019",
        event: "Started studies at Lycée Lenster, focusing on Smart Technologies, including electronics and programming."
    },
    {
        date: "2020",
        event: "Transferred to Lycée des Arts et Métiers into the IT program. The first year included learning HTML, CSS, and operating systems (Windows and Linux)."
    },
    {
        date: "2021",
        event: "Completed the second year of the IT program with a focus on JavaScript and network fundamentals using Packet Tracer. Also completed an internship in IT Support and Network Infrastructure at Sodexo, contributing to network maintenance and support tasks."
    },
    {
        date: "2022",
        event: "Advanced to the third year, specializing in PHP, MySQL database management, and server setup for both Linux and Windows environments."
    },
    {
        date: "2023",
        event: "Undertook the fourth year, focusing on REST APIs, jQuery, and developing web, database, and mail servers. Also completed an internship in Web Development at LogFire, where I gained experience with React and Bootstrap."
    },
    {
        date: "2024",
        event: "Graduated from the IT program at Lycée des Arts et Métiers with a comprehensive understanding of various technologies and practical skills."
    }
];

const Timeline = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000, // Dauer der Animation in ms
        });
    }, []);
    return (
        <section id="timeline">
            <Container className="timeline-container p-5">
                <h2 className="text-center display-4 fw-bold mt-5 mb-5 ">My <span className="text-success" >Timeline</span></h2>
                {timelineData.map((item, index) => (
                    <Row key={index} data-aos={"fade-up"} className={`timeline-row ${index % 2 === 0 ? 'left' : 'right'}`}>
                        <Col md={6} className={`timeline-content boxRight  ${index % 2 === 0 ? 'text-end  ' : 'text-start '}`}>
                            <div className="timeline-date fw-bold boxRight">{item.date}</div>
                            <div className="timeline-event boxRight">{item.event}</div>
                        </Col>
                    </Row>
                ))}
            </Container>
        </section>
    );
}

export default Timeline;
