import React, {useEffect} from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import '../styles/navbar.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
const MyNavbar = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000, // Dauer der Animation in ms
        });
    }, []);
    return (
        <Navbar className={"box"} expand="lg" bg="light" style={{ zIndex: 100 }} data-aos={"fade-down"}>
            <Container>
                <Navbar.Brand href="#" className="text-success fw-bold">Frisch Tim</Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarNav" />
                <Navbar.Collapse id="navbarNav">
                    <Nav className="ms-auto">
                        <Nav.Link href="#home" className="nav-link">Home</Nav.Link>
                        <Nav.Link href="#aboutme" className="nav-link">About Me</Nav.Link>
                        <Nav.Link href="#aboutme" className="nav-link">Timeline</Nav.Link>
                        <Nav.Link href="#skills" className="nav-link">Skills</Nav.Link>
                        <Nav.Link href="#projects" className="nav-link">Projects</Nav.Link>
                        <Nav.Link href="#contact" className="nav-link">Contact</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default MyNavbar;
