import React, {useEffect, useState} from 'react';
import { Button, Container, Card } from 'react-bootstrap';
import '../styles/about.css';
import '../styles/skills.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
// Bilder importieren
import HTMLImage from '../images/HTML.png';
import CSSImage from '../images/CSS.png';
import JavaScriptImage from '../images/JavaScript.png';
import ReactImage from '../images/React.png';
import PHPImage from '../images/PHP.png';
import MySQLImage from '../images/MySQL.png';
import BashImage from '../images/Bash.png';
import PowerShellImage from '../images/PowerShell.png';
import JQueryImage from '../images/JQuery.png';
import BootstrapImage from '../images/Bootstrap.png';
import GitHubImage from '../images/GitHub.png';
import PHPMyAdmin from '../images/PHPMyAdmin.png';
import MariaDB from '../images/MariaDB.png';
import SQL from '../images/SQL.png';
import PHPStorm from '../images/PHPStorm.png';
import VSCode from '../images/VSCode.png';
import API from '../images/API.png';
import Figma from '../images/Figam.png';
import Express from '../images/Express.png';
import NodeJS from '../images/NodeJS.png';
import SCSS from '../images/SCSS.png';
import Git from '../images/Git.png';
import Postman from '../images/Postman.png';

// Skill-Kategorien definieren
const skillsCategories = [
    {
        category: "Frontend Skills",
        skills: [
            { name: 'HTML', level: '100%', image: HTMLImage, widthClass: 'wide' },
            { name: 'CSS', level: '90%', image: CSSImage, widthClass: 'narrow' },
            { name: 'JavaScript', level: '65%', image: JavaScriptImage, widthClass: 'wide' },
            { name: 'React', level: '65%', image: ReactImage, widthClass: 'narrow' },
            { name: 'JQuery', level: '75%', image: JQueryImage, widthClass: 'narrow' },
            { name: 'Bootstrap', level: '80%', image: BootstrapImage, widthClass: 'wide' },
            { name: 'SCSS', level: '80%', image: SCSS, widthClass: 'wide' },
        ]
    },
    {
        category: "Backend Skills",
        skills: [
            { name: 'PHP', level: '75%', image: PHPImage, widthClass: 'narrow' },
            { name: 'MySQL', level: '60%', image: MySQLImage, widthClass: 'wide' },
            { name: 'Bash', level: '50%', image: BashImage, widthClass: 'narrow' },
            { name: 'PowerShell', level: '60%', image: PowerShellImage, widthClass: 'wide' },
            { name: 'Node.js', level: '60%', image: NodeJS, widthClass: 'narrow' },
            { name: 'Express.js', level: '60%', image: Express, widthClass: 'narrow' },
            { name: 'MariaDB', level: '90%', image: MariaDB, widthClass: 'wide' },
            { name: 'PHPMyAdmin', level: '90%', image: PHPMyAdmin, widthClass: 'wide' },
            { name: 'SQL', level: '90%', image: SQL, widthClass: 'wide' },
        ]
    },
    {
        category: "Tools",
        skills: [
            { name: 'GitHub', level: '90%', image: GitHubImage, widthClass: 'narrow' },
            { name: 'PhpStorm', level: '90%', image: PHPStorm, widthClass: 'wide' },
            { name: 'Visual Studio Code', level: '90%', image: VSCode, widthClass: 'wide' },
            { name: 'RESTful APIs', level: '90%', image: API, widthClass: 'narrow' },
            { name: 'Figma', level: '90%', image: Figma, widthClass: 'narrow' },
            { name: 'Git', level: '80%', image: Git, widthClass: 'narrow' }, // Neues Tool
            { name: 'Postman', level: '85%', image: Postman, widthClass: 'narrow' } // Neues Tool
        ]
    }
];

const FilterButton = ({ category, isActive, onClick }) => (
    <Button
        variant={isActive ? 'success' : 'outline-success'}
        onClick={onClick}
        className="m-2 rounded-pill px-3"
        style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
    >
        {category}
    </Button>
);

const Skills = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000, // Dauer der Animation in ms
        });
    }, []);
    const [filter, setFilter] = useState('All');

    const filteredSkills = filter === 'All' ? skillsCategories : skillsCategories.filter(category => category.category === filter);

    return (
        <section id="skills" className="bg-light text-dark p-5">
            <Container fluid={true}>
                <div className="text-center mb-4 box">
                    <h1 className="display-4 fw-bold mb-4 text-dark">My <span className="text-success">Skills</span></h1>
                    <div className="d-flex justify-content-center flex-wrap">
                        <FilterButton
                            category="All"
                            isActive={filter === 'All'}
                            onClick={() => setFilter('All')}
                        />
                        {skillsCategories.map((category, index) => (

                            <FilterButton
                                data-aos={"fade-up"}
                                key={index}
                                category={category.category}
                                isActive={filter === category.category}
                                onClick={() => setFilter(category.category)}
                            />
                        ))}
                    </div>
                </div>
                {filteredSkills.map((category, index) => (
                    <React.Fragment key={index}>
                        <h3 className="text-center mt-5 mb-4">{category.category}</h3>
                        <div className="scroll-container">
                            <div className="scroll-content "  data-aos={"fade-up"}>
                                {category.skills.concat(category.skills).map((skill, skillIndex) => (
                                    <div key={skillIndex} className={`skill-item ${skill.widthClass}`}>
                                        <Card className="skill-card shadow">
                                            <Card.Img variant="top" src={skill.image} className="p-3" />
                                            <Card.Body className="text-center">
                                                <Card.Title className="text-success">{skill.name}</Card.Title>
                                                <Card.Text>
                                                    Proficiency: {skill.level}
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </React.Fragment>
                ))}
            </Container>
        </section>
    );
}

export default Skills;
