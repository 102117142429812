import React, {useEffect} from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

import '../styles/about.css';

import AOS from 'aos';
import 'aos/dist/aos.css';

function About() {
    useEffect(() => {
        AOS.init({
            duration: 1000, // Dauer der Animation in ms
        });
    }, []);
    return (
        <>
        <section id="aboutme" className="bg-light p-5 custom-border view">
            <Container className="rounded p-5 bg-danger text-dark mt-5 mb-5 shadow">
                <Row>
                    <Col md={6} className="box">
                        <p data-aos={"fade-right"}><strong className="text-success box">NAME:</strong> Frisch Tim</p>
                        <p data-aos={"fade-right"}><strong className="text-success box">AGE:</strong> 21 Years</p>
                        <p data-aos={"fade-right"}><strong className="text-success box">LOCATION:</strong> Junglinster, Luxembourg</p>
                        <p data-aos={"fade-right"}><strong className="text-success box">DEGREE:</strong> Diploma of Technician in Computer Science (DT)</p>
                        <p data-aos={"fade-right"}><strong className="text-success box">PHONE:</strong> +352 621 816 424</p>
                        <p data-aos={"fade-right"}><strong className="text-success box">EMAIL:</strong> <a href="mailto:timfrisch530@gmail.com">timfrisch530@gmail.com</a></p>
                    </Col>
                    <Col md={6} className="text-center box" data-aos={"fade-left"}>
                        <p className="text-dark">Hello! I am Tim Frisch, a passionate 21-year-old from Luxembourg with a Diploma of Technician in Computer Science. I thrive on problem-solving and love technology.</p>
                        <p className="text-dark">I bring dedication and positive energy to every project. My enthusiasm for programming makes me more than just a developer; it is a part of who I am.</p>
                        <Button variant="outline-success" size="lg" className="mt-4 box">Download CV</Button>
                    </Col>
                </Row>
            </Container>
        </section>

        </>
    );
}

export default About;
