import React, {useEffect} from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';
// Projekt-Daten
const projectData = [
    {
        title: "Coca-Cola",
        description: "Developed an E-Commerce platform for Coca-Cola products, allowing users to browse, view details, and purchase items online.",
        technologies: "HTML, CSS, JavaScript, jQuery, PHP, MySQL, Bootstrap",
        image: require('../images/projects/Cola.png'),
        link: "https://cola.timfrisch.net/"
    },
    {
        title: "Bavaria",
        description: "Created a shopping site as part of a school project, using PHP and MySQL.",
        technologies: "HTML, CSS, PHP, MySQL",
        image: require('../images/projects/EcoWeighing.png'),
        link: "https://ecoweighing.timfrisch.net/"
    },
    {
        title: "Luxlait",
        description: "Developed an E-Commerce platform for Luxlait products, including product browsing, detail viewing, and online purchasing.",
        technologies: "HTML, CSS, JavaScript, jQuery, PHP, MySQL, Bootstrap",
        image: require('../images/projects/luxlait.png'),
        link: "https://luxlait.timfrisch.net/"
    },
    {
        title: "GreenTech",
        description: "Created a shopping site as part of a school project, utilizing PHP and MySQL.",
        technologies: "HTML, CSS, PHP, MySQL",
        image: require('../images/projects/garden.png'),
        link: "https://gardentech.timfrisch.net/"
    }
];

function Projects() {
    useEffect(() => {
        AOS.init({
            duration: 1000, // Dauer der Animation in ms
        });
    }, []);
    return (
        <section id="projects" className="p-3 p-md-5 bg-light" >
            <Container className="text-center text-dark">
                <h1 className="display-5 mb-5 fw-bold">
                    My <span className="text-success">Projects</span>
                </h1>
                <Row>
                    {projectData.map((project, index) => (
                        <Col key={index} xs={12} md={12} lg={12} className="mb-4">
                            <div className="project-container boxRight " data-aos="fade-right">
                                <Row noGutters className="d-flex align-items-center">
                                    <Col md={4}>
                                        <Card.Img src={project.image} alt={project.title} className="img-fluid"  />
                                    </Col>
                                    <Col md={8}>
                                        <div className="project-content boxLeft">
                                            <Card.Title>{project.title}</Card.Title>
                                            <Card.Text>{project.description}</Card.Text>
                                            <Card.Text>
                                                <small className="text-muted">{project.technologies}</small>
                                            </Card.Text>
                                            <a href={project.link} target="_blank" rel="noreferrer" className="btn btn-outline-success">View Project</a>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    );
}

export default Projects;
